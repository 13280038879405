import { Box, Heading, Text } from "@chakra-ui/react";

function TermsAndConditionsPage() {
 return (
  <Box
   //    border={"2px solid red"}
   w="75%"
   margin={"auto"}
   padding="8"
   backgroundColor="white"
   borderRadius="lg"
   boxShadow="md"
  >
   <Heading textAlign="center" fontSize="2xl" color="#F56A01" marginBottom="6">
    Terms & Conditions for LSSF TRUST
   </Heading>
   <Box padding="4" backgroundColor="gray.50" borderRadius="md" boxShadow="sm">
    <Text fontSize="md" lineHeight="1.5" color="gray.600" marginBottom="4">
     Welcome to{" "}
     <span style={{ color: "blue", cursor: "pointer" }}>www.lssftrust.org</span>{" "}
     By using our website, you agree to comply with and be bound by the
     following terms and conditions:
    </Text>

    {/* Section 1 */}
    <Box>
     <Heading
      as="h3"
      size="md"
      marginTop="10"
      marginBottom="2"
      color="gray.700"
      fontWeight="bold"
     >
      1. Use of the Website
     </Heading>
     <Text fontSize="md" lineHeight="1.5" color="gray.600" marginBottom="4">
      The content on this Site is for general information and use only. It is
      subject to change without notice. You agree to use the Site for lawful
      purposes only.
     </Text>
    </Box>

    {/* Section 2 */}
    <Box>
     <Heading
      as="h3"
      size="md"
      marginTop="10"
      marginBottom="2"
      color="gray.700"
      fontWeight="bold"
     >
      2. Intellectual Property
     </Heading>
     <Text fontSize="md" lineHeight="1.5" color="gray.600" marginBottom="4">
      All materials on this Site, including text, images, and software, are the
      property of LSSF TRUST. Unauthorized use or reproduction is prohibited.
     </Text>
    </Box>

    {/* Section 3 */}
    <Box>
     <Heading
      as="h3"
      size="md"
      marginTop="10"
      marginBottom="2"
      color="gray.700"
      fontWeight="bold"
     >
      3. Limitation of Liability
     </Heading>
     <Text fontSize="md" lineHeight="1.5" color="gray.600" marginBottom="4">
      LSSF TRUST does not warrant the accuracy, completeness, or suitability of
      the information on this Site for any particular purpose. Your use of the
      Site is at your own risk. We are not liable for any damages arising from
      your use of the Site.
     </Text>
    </Box>

    {/* Section 4 */}
    <Box>
     <Heading
      as="h3"
      size="md"
      marginTop="10"
      marginBottom="2"
      color="gray.700"
      fontWeight="bold"
     >
      4. External Links
     </Heading>
     <Text fontSize="md" lineHeight="1.5" color="gray.600" marginBottom="4">
      The Site may contain links to other websites. LSSF TRUST is not
      responsible for the content or privacy practices of these external sites.
     </Text>
    </Box>

    {/* Section 5 */}
    <Box>
     <Heading
      as="h3"
      size="md"
      marginTop="10"
      marginBottom="2"
      color="gray.700"
      fontWeight="bold"
     >
      5. Governing Law
     </Heading>
     <Text fontSize="md" lineHeight="1.5" color="gray.600" marginBottom="4">
      These terms and conditions are governed by the laws of India. Any disputes
      arising from the use of the Site will be subject to the jurisdiction of
      the courts in India.
     </Text>
    </Box>

    {/* Section 6 */}
    <Box>
     <Heading
      as="h3"
      size="md"
      marginTop="10"
      marginBottom="2"
      color="gray.700"
      fontWeight="bold"
     >
      6. Changes to Terms & Conditions
     </Heading>
     <Text fontSize="md" lineHeight="1.5" color="gray.600" marginBottom="4">
      LSSF TRUST reserves the right to modify these terms and conditions at any
      time. Changes will be posted on this page with an updated effective date.
     </Text>
    </Box>

    <Text fontSize="md" lineHeight="1.5" color="gray.600" marginBottom="4">
     By using our Site, you agree to these terms and conditions. If you do not
     agree, please do not use our Site.
    </Text>
    <Text fontSize="md" lineHeight="1.5" color="gray.600" marginBottom="4">
     For any questions or concerns, please contact us at{" "}
     <span style={{ color: "blue", cursor: "pointer" }}>www.lssftrust.org</span>{" "}
    </Text>
   </Box>
  </Box>
 );
}

export default TermsAndConditionsPage;

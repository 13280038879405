import { Box, Heading, Text, UnorderedList, ListItem } from "@chakra-ui/react";

function PrivacyPolicyPage() {
 return (
  <Box
   w="75%"
   margin={"auto"}
   padding="8"
   backgroundColor="white"
   borderRadius="lg"
   boxShadow="md"
  >
   <Heading textAlign="center" fontSize="2xl" color="#F56A01" marginBottom="6">
    Privacy Policy for LSSF TRUST
   </Heading>
   <Box padding="4" backgroundColor="gray.50" borderRadius="md" boxShadow="sm">
    <Text fontSize="md" lineHeight="1.5" color="gray.600" marginBottom="4">
     LSSF TRUST is committed to the ethical collection, retention, and use of
     information provided by you on our website{" "}
     <span style={{ color: "blue", cursor: "pointer" }}>www.lssftrust.org</span>{" "}
     ('Site'). This Privacy Policy outlines how we collect, use, and protect
     your personal information.
    </Text>

    {/* Section 1 */}
    <Box>
     <Heading
      as="h3"
      size="md"
      marginTop="10"
      marginBottom="2"
      color="gray.700"
      fontWeight="bold"
     >
      1. Collection of Personal Information
     </Heading>
     <Text fontSize="md" lineHeight="1.5" color="gray.600" marginBottom="4">
      We may collect the following personal information:
     </Text>
     <UnorderedList marginBottom="4">
      <ListItem>Name</ListItem>
      <ListItem>Age</ListItem>
      <ListItem>Occupation</ListItem>
      <ListItem>Email and postal address</ListItem>
      <ListItem>Telephone number</ListItem>
      <ListItem>Payment processing details</ListItem>
      <ListItem>Limited personal details</ListItem>
      <ListItem>Any other data the website may require</ListItem>
     </UnorderedList>
    </Box>

    {/* Section 2 */}
    <Box>
     <Heading
      as="h3"
      size="md"
      marginTop="10"
      marginBottom="2"
      color="gray.700"
      fontWeight="bold"
     >
      2. Use of Personal Information
     </Heading>
     <Text fontSize="md" lineHeight="1.5" color="gray.600" marginBottom="4">
      LSSF TRUST uses personal information for the following purposes:
     </Text>
     <UnorderedList marginBottom="4">
      <ListItem>
       Sending newsletters, updates, and promotional materials related to our
       activities.
      </ListItem>
      <ListItem>Processing donations and providing receipts.</ListItem>
      <ListItem>
       Maintaining an internal confidential database of all personal information
       collected from visitors to the Site.
      </ListItem>
      <ListItem>
       Evaluating and administering the Site's activities, responding to
       concerns, and assessing visitor trends.
      </ListItem>
     </UnorderedList>
    </Box>

    {/* Section 3 */}
    <Box>
     <Heading
      as="h3"
      size="md"
      marginTop="10"
      marginBottom="2"
      color="gray.700"
      fontWeight="bold"
     >
      3. Disclosure of Personal Information
     </Heading>
     <Text fontSize="md" lineHeight="1.5" color="gray.600" marginBottom="4">
      Access to personal information is limited to authorized personnel within
      LSSF TRUST. We may share personal information with third parties involved
      in the operation of our Site, such as payment processors or email service
      providers. LSSF TRUST may also disclose personal information if required
      by law or to protect the rights and property of LSSF TRUST and its
      stakeholders.
     </Text>
    </Box>

    {/* Add other sections as needed */}

    {/* Section 4 */}
    <Box>
     <Heading
      as="h3"
      size="md"
      marginTop="10"
      marginBottom="2"
      color="gray.700"
      fontWeight="bold"
     >
      4. Security
     </Heading>
     <Text fontSize="md" lineHeight="1.5" color="gray.600" marginBottom="4">
      LSSF TRUST uses appropriate security measures to protect your personal
      information. However, we cannot guarantee the absolute security of your
      data.
     </Text>
    </Box>

    {/* Section 5 */}
    <Box>
     <Heading
      as="h3"
      size="md"
      marginTop="10"
      marginBottom="2"
      color="gray.700"
      fontWeight="bold"
     >
      5. Copyright Protection
     </Heading>
     <Text fontSize="md" lineHeight="1.5" color="gray.600" marginBottom="4">
      All content on this Site, including graphics, text, logos, and software,
      is the property of LSSF TRUST and protected by copyright laws.
      Unauthorized reproduction or use of the content is prohibited.
     </Text>
    </Box>

    {/* Section 6 */}
    <Box>
     <Heading
      as="h3"
      size="md"
      marginTop="10"
      marginBottom="2"
      color="gray.700"
      fontWeight="bold"
     >
      6. Disclaimer
     </Heading>
     <Text fontSize="md" lineHeight="1.5" color="gray.600" marginBottom="4">
      LSSF TRUST does not guarantee the accuracy or completeness of the
      information on the Site. Use of the Site and its content is at your own
      risk.
     </Text>
    </Box>

    {/* Section 7 */}
    <Box>
     <Heading
      as="h3"
      size="md"
      marginTop="10"
      marginBottom="2"
      color="gray.700"
      fontWeight="bold"
     >
      7. Changes to this Privacy Policy
     </Heading>
     <Text fontSize="md" lineHeight="1.5" color="gray.600" marginBottom="4">
      LSSF TRUST reserves the right to update this Privacy Policy at any time.
      Changes will be posted on this page with an updated effective date.
     </Text>
    </Box>
   </Box>
  </Box>
 );
}

export default PrivacyPolicyPage;
